<template>
  <div class="container">
    <div class="p404">
      <div>
        <strong>
          404
        </strong>
        잘못된 접근입니다
      </div>
      <!-- <router-link :to="$router.go(-1)">이전페이지로</router-link> -->
    </div>
  </div><!--/container-->
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style>
  .p404 {
    display: flex;
    height: 100vh;
    padding-top: 100px;
    justify-content: center;
    color: #aaa;
    font-size: 30px;
  }
  .p404 strong {
    display: block;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
  }
</style>
